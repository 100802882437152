.App {
  text-align: center;
}

body {
  background: #FFF;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* .icon-wrapper {
  left: 12px;
  right: auto !important;
} */

/* .row-title-text {
  padding-right: 0 !important;
  padding-left: 3em;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0);
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px #e25555; */
  background-color: #e25555;
}

/* .MuiGrid-root.terminal-container > div {
  width: 100%;
  height: 40vh !important;
} */

.terminal-container {
  width: 100%;
  margin: 0rem auto;
  border: 0;
  border-radius: 5px;
  height: 40vh;
  scrollbar-width: 0;
}